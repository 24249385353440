<template>
  <button
    v-if="skeleton"
    class="action skeleton"
    :class="aspectClass"
    :disabled="disabled"
  >
    <span class="action__inner">
      <span class="action__text">&nbsp;</span>
    </span>
  </button>
  <a
    v-else-if="type === 'link'"
    class="action"
    :class="aspectClass"
    @touchstart="touchstart"
    @touchmove="touchmove"
    @touchend="touchend"
    @click="click"
  >
    <span class="action__inner">
      <icon-carret
        v-if="withArrow && arrowPosition === 'left' && size === 'secondary'"
        class="action__icon action__icon--left"
      />
      <icon
        v-else-if="withArrow && arrowPosition === 'left'"
        :name="iconName"
        class="action__icon action__icon--left"
      />
      <span
        class="action__text"
        :class="{ 'visually-hidden': textVisuallyHidden }"
        >{{ text }}</span
      >
      <transition name="loading">
        <span v-if="loading" class="action__loading">
          <icon-loading />
        </span>
      </transition>
      <transition name="loading">
        <span v-if="success" class="action__loading"> &#10004; </span>
      </transition>
      <icon
        v-if="withArrow && arrowPosition === 'right'"
        :name="iconName"
        class="action__icon"
      />
    </span>
  </a>
  <nuxt-link
    v-else-if="type === 'nuxt-link'"
    :to="to"
    class="action"
    :class="aspectClass"
    @click.native="nuxtLinkClick"
  >
    <span class="action__inner">
      <icon
        v-if="withArrow && arrowPosition === 'left'"
        :name="iconName"
        class="action__icon action__icon--left"
      />
      <span
        class="action__text"
        :class="{ 'visually-hidden': textVisuallyHidden }"
        >{{ text }}</span
      >
      <transition name="loading">
        <span v-if="loading || selfLoading" class="action__loading">
          <icon-loading />
        </span>
      </transition>
      <transition name="loading">
        <span v-if="success" class="action__loading"> &#10004; </span>
      </transition>
      <icon
        v-if="withArrow && arrowPosition === 'right'"
        :name="iconName"
        class="action__icon"
      />
    </span>
  </nuxt-link>
  <button
    v-else
    :type="type"
    class="action"
    :class="aspectClass"
    @touchstart="touchstart"
    @click="click"
  >
    <span class="action__inner">
      <icon
        v-if="withArrow && arrowPosition === 'left'"
        :name="iconName"
        class="action__icon action__icon--left"
      />

      <span
        class="action__text"
        :class="{ 'visually-hidden': textVisuallyHidden }"
        >{{ text }}</span
      >
      <transition name="loading">
        <span v-if="loading" class="action__loading">
          <icon-loading />
        </span>
      </transition>
      <transition name="loading">
        <span v-if="success" class="action__loading"> &#10004; </span>
      </transition>
      <icon
        v-if="withArrow && arrowPosition === 'right'"
        :name="iconName"
        class="action__icon"
      />
    </span>
  </button>
</template>

<script>
import IconLoading from '@/components/icons/Loading'
import Icon from '@/components/icons/Icon'

export default {
  name: 'PrimaryButton',
  components: {
    IconLoading,
    Icon,
  },
  props: {
    /**
     * Texte du bouton
     */
    text: { type: String, required: true },
    /**
     * Doit-il y avoir une flèche
     */
    withArrow: { type: Boolean, default: false },
    /**
     * Quel est la position de la flèche
     */
    arrowPosition: {
      type: String,
      default: 'right',
      validator: (position) => {
        return ['left', 'right'].includes(position)
      },
    },
    /**
     * Quel est l'orientation de la flèche
     */
    arrowDirection: {
      type: String,
      default: 'right',
      validator: (position) => {
        return ['right', 'left', 'up', 'down'].includes(position)
      },
    },
    /**
     * Est-ce que la flèche se transforme en icône ?
     */
    arrowIsIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * l'icon qui doit remplacer la flèche
     */
    icon: {
      type: String,
      default: '',
    },
    /**
     * Comment le bouton est affiché
     */
    aspect: {
      type: String,
      default: 'dark',
      validator: (value) => {
        return ['dark', 'light', 'yellow', 'transparent'].includes(value)
      },
    },
    /**
     * Défini la hauteur du bouton
     */
    size: {
      type: String,
      default: 'primary',
      validator: (value) => {
        return ['primary', 'secondary', 'tertiary'].includes(value)
      },
    },
    /**
     * Défini l'alignement du texte du bouton
     */
    align: {
      type: String,
      default: 'left',
      validator: (value) => {
        return ['left', 'center', 'right'].includes(value)
      },
    },
    /**
     * Défini le type d'action
     */
    type: {
      type: String,
      default: 'link',
      validator: (value) => {
        return ['link', 'nuxt-link', 'button', 'submit'].includes(value)
      },
    },
    to: {
      type: [String, Object],
      default: () => {
        return ''
      },
    },
    /**
     * Le texte est-il visuellement caché
     */
    textVisuallyHidden: {
      type: Boolean,
      default: false,
    },
    /**
     * Le bouton est-il en chargement
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Le bouton est-il en état de réussite
     */
    success: {
      type: Boolean,
      default: false,
    },
    /**
     * Le bouton est-il en état d'erreur
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * Le bouton est-il en état disable
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Retire l'ombre portée
     */
    noShadow: {
      type: Boolean,
      default: false,
    },
    /**
     * Le bouton est affiché en squelette de chargement
     */
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ selfLoading: false }),
  computed: {
    aspectClass() {
      const c = []

      c.push(`action--${this.aspect}`)
      c.push(`action--${this.size}`)
      c.push(`action--${this.align}`)

      if (this.withArrow) {
        c.push('action--arrow')
        c.push(`action--arrow--${this.arrowPosition}`)
      }

      if (this.textVisuallyHidden) {
        c.push('action--only-icon')
      }

      if (this.noShadow) {
        c.push('action--no-shadow')
      }

      if (this.loading) {
        c.push('action--loading')
      }

      if (this.disabled) {
        c.push('action--disabled')
      }

      if (this.error) {
        c.push('action--error')
      }

      return c
    },
    iconName() {
      if (this.arrowIsIcon) {
        return this.icon
      }

      return `arrow-${this.arrowDirection}`
    },
  },
  methods: {
    click(ev) {
      /**
       * Optional click handler
       *
       * @event click
       */
      if (!this.loading) {
        this.$emit('click', ev)
      }
    },
    touchstart(ev) {
      /**
       * Optional click handler
       *
       * @event click
       */
      if (!this.loading) {
        this.$emit('touchstart', ev)
      }
    },
    touchmove(ev) {
      /**
       * Optional click handler
       *
       * @event click
       */
      if (!this.loading) {
        this.$emit('touchmove', ev)
      }
    },
    touchend(ev) {
      /**
       * Optional click handler
       *
       * @event click
       */
      if (!this.loading) {
        this.$emit('touchend', ev)
      }
    },
    nuxtLinkClick() {
      this.selfLoading = true
    },
  },
}
</script>

<style lang="scss">
.action {
  font-weight: 700;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  display: block;
  position: relative;
  width: auto;
  text-align: center;
  transition: all 0.3s;

  &:not(.action--error) {
    @include on-hover-and-focus {
      .icon--arrow {
        &-left {
          transform: translateX(-5px);
        }

        &-right {
          transform: translateX(5px);
        }

        &-up {
          transform: translateY(-5px);
        }

        &-down {
          transform: translateY(5px);
        }
      }

      &--arrow--left {
        .icon--arrow {
          &-left {
            transform: translateX(5px);
          }

          &-right {
            transform: translateX(-5px);
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__icon {
    position: relative;
    margin-left: rem(10px);
    transition: transform 0.3s;
    flex-shrink: 0;

    &.icon,
    .icon {
      width: rem(16px);
      height: rem(16px);
    }

    &--left {
      margin-left: 0;
      margin-right: rem(10px);
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    padding: 0 rem(10px);
    pointer-events: none;
    transition: all 0.3s;

    svg {
      width: rem(24px);
      height: rem(24px);
    }
  }

  &:disabled,
  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--loading {
    cursor: wait;

    .action__text {
      opacity: 0.75;
    }
  }

  &--left {
    .action__inner {
      justify-content: flex-start;
    }
  }

  &--center {
    .action__inner {
      justify-content: center;
    }
  }

  &--right {
    .action__inner {
      justify-content: flex-end;
    }
  }

  &--dark {
    color: var(--tertiary-color);
    background: var(--secondary-color);
    box-shadow: rem(5px) rem(5px) 0 rgba($primary-color, 0.1);

    &:not(.action--error) {
      @include on-hover-and-focus {
        color: var(--secondary-color);
        background: $yellow;
        box-shadow: rem(7px) rem(7px) 0 rgba($primary-color, 0.1);
      }
    }
  }

  &--light {
    color: var(--secondary-color);
    background: var(--tertiary-color);
    box-shadow: rem(5px) rem(5px) 0 rgba($primary-color, 0.1);

    &:not(.action--error) {
      @include on-hover-and-focus {
        background: $yellow;
        color: var(--secondary-color);
        box-shadow: rem(7px) rem(7px) 0 rgba($primary-color, 0.1);
      }
    }
  }

  &--yellow {
    background: $yellow;
    color: var(--secondary-color);

    &:not(.action--error) {
      @include on-hover-and-focus {
        background: var(--secondary-color);
        color: var(--tertiary-color);
      }
    }
  }

  &--transparent {
    background: transparent;
  }

  &--primary {
    height: rem(40px);
    font-family: var(--secondary-font-family);
    font-size: var(--button-font-size-primary);
    line-height: 1;
    padding: 0 calc(var(--spacing) * 1.5);

    @include mq($from: tablet) {
      padding: 0 calc(var(--spacing) * 1.5);
    }
  }

  &--secondary {
    font-family: var(--primary-font-family);
    font-size: var(--button-font-size-secondary);
    padding: 0 var(--spacing);
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    min-height: rem(30px);
    display: flex;
    align-items: center;

    @include mq($from: tablet) {
      min-height: rem(35px);
    }

    .action__icon {
      width: rem(12px);
      height: rem(12px);
    }

    &.action--disabled {
      background: transparent;
      color: var(--secondary-color-3);
      border: 1px solid var(--secondary-color-3);
      opacity: 1;
    }
  }

  &--tertiary {
    font-size: var(--label-font-size);
    text-transform: uppercase;
    font-weight: 600;
    padding: calc(var(--spacing) * 0.4) var(--spacing);

    .action__text {
      position: relative;
      top: rem(1px);
    }
  }

  &--link {
    background: none;
    padding: 0;
    font-weight: 400;
  }

  &--arrow {
    padding-right: calc(var(--spacing) * 2 - 5px);
    padding-left: calc(var(--spacing) * 2);

    .action__text {
      white-space: nowrap;
    }
  }

  &--only-icon {
    padding-right: calc(var(--spacing) * 2);

    .action__icon {
      margin: 0;
    }
  }

  &--no-shadow {
    // Important because if required by prop it is important no matter potential external customisation
    box-shadow: none !important;
  }

  &--error {
    box-shadow: 0 0 0 rem(2px) var(--error-border);
    cursor: not-allowed;
  }

  &.skeleton {
    background: #e3e3e3;
    border: 0;
    cursor: wait;
  }
}
</style>
