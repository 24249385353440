import axios, { AxiosPromise } from 'axios'

interface iCustomerOrderShipping {
  initialShippingMethod: 'RELAIS' | 'DOMICILE' | 'NO_SHIPPING'
  actualShippingMethod: 'RELAIS' | 'DOMICILE' | 'NO_SHIPPING'
  availableShippingMethods: ('RELAIS' | 'DOMICILE')[]
  channel: 'ONLINE' | 'RETAIL'
  shippingAdress?: {
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    zip: string
    country: string
    phone: string
  }
  pickupPointData?: {
    id: string
    name: string
    street: string
    postalCode: string
    city: string
    countryCode: string
    latitude: string
    longitude: string
    distance: number
    openingHours: {
      start: string
      end: string
    }[][]
    onHoliday: boolean
  }
}

class AccountManagerService {
  private baseUrl: string
  private headers: Object

  constructor() {
    if (!process.env.ACCOUNT_MANAGER_URL) {
      throw new Error('Missing env var ACCOUNT_MANAGER_URL')
    }
    this.baseUrl = process.env.ACCOUNT_MANAGER_URL
    this.headers = {
      Accept: 'application/json',
    }
  }

  getCustomerStatus(email: string): AxiosPromise<any> {
    return axios.post(
      `${this.baseUrl}/customers/status`,
      {
        email,
      },
      { headers: this.headers }
    )
  }

  sendActivationMail(
    id: string,
    email: string,
    locale: string,
    context: string
  ): AxiosPromise<any> {
    return axios.post(
      `${this.baseUrl}/customers/${id}/activate`,
      {
        email,
        locale,
        context,
      },
      { headers: this.headers }
    )
  }

  generateMultipassToken(
    email: string,
    returnUrl: string,
    ip = ''
  ): AxiosPromise<any> {
    const body: any = {
      email,
      return_to: returnUrl,
    }

    if (ip.length > 0) {
      body.remote_ip = ip
    }

    return axios.post(`${this.baseUrl}/customers/login`, body, {
      headers: this.headers,
    })
  }

  updateCustomer(customer: any): AxiosPromise<any> {
    return axios.post(`${this.baseUrl}/customers/${customer.id}`, customer, {
      headers: this.headers,
    })
  }

  getCustomer(id: string): AxiosPromise<any> {
    return axios.get(`${this.baseUrl}/customers/${id}`)
  }

  getCustomerOrderShipping(
    customerId: string,
    orderId: string
  ): Promise<iCustomerOrderShipping | null> {
    return axios
      .get(`${this.baseUrl}/customers/${customerId}/orders/${orderId}`, {
        headers: this.headers,
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data.data as iCustomerOrderShipping
        }

        return null
      })
  }

  updateOrderShippingAddress(
    customerId: string,
    orderId: string,
    shippingMethod: 'RELAIS' | 'DOMICILE',
    shippingAdressOrPickUpPointData: any
  ) {
    return fetch(`${this.baseUrl}/customers/${customerId}/orders/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        shippingMethod === 'RELAIS'
          ? {
              shippingMethod,
              pickupPointData: shippingAdressOrPickUpPointData,
            }
          : {
              shippingMethod,
              shippingAddress: shippingAdressOrPickUpPointData,
            }
      ),
    })
      .then((r) => r.json())
      .then((response) => {
        if (response.status === 'success') {
          return response.data
        }

        throw new Error(response.message)
      })
  }

  getPickUpPoints(address: {
    street?: string
    postalCode?: string
    city?: string
    countryCode?: string
  }) {
    return axios.get(`${this.baseUrl}/pickup-points`, {
      params: { ...address },
      headers: this.headers,
    })
  }
}

export default new AccountManagerService()
