import { impactBlock } from './blocks/impact'
import { issuesBlock } from './blocks/issues'
import { simpleContentBlock } from './blocks/simpleContent'
import { subscribeBlock } from './blocks/subscribe'
import { titleBlock } from './blocks/title'
import { tracabiliteBlock } from './blocks/tracabilite'
// import { videoBlock } from './blocks/video'
import { columnBlock } from './blocks/column'
import { galleryBlock } from './blocks/gallery'
import { imageFullBlock } from './blocks/imageFull'
import { mosaicBlock } from './blocks/mosaic'
import { quoteBlock } from './blocks/quote'
import { ctaTextBlock } from './blocks/ctaText'
import { ctaImageBlock } from './blocks/ctaImage'
import { assuranceBlock } from './blocks/assurance'
import { backgroundContentBlock } from './blocks/backgroundContent'
import { morphoBlock } from './blocks/morpho'
// import { avisClientBlock } from './blocks/avisClient'
import { baniereBlock } from './blocks/baniere'
// import { productsListAutoBlock } from './blocks/products/listAuto'
// import { productListShopifyCollectionBlock } from './blocks/products/listShopify'
import { galleryOfProductsAndLinksBlock } from './blocks/products/listOfProductAndLinks'
import { portableTextWithInternalLinks } from './utils/portableText'
import { videoBlock } from './blocks/video'
import { tableBlock } from './blocks/table'
import { sizeGuideImagesBlock } from './blocks/sizeGuideImages'
import { teasingVideoBlock } from './blocks/teasingVideo'

export const blocks = `
  blocks[]{
    ...,
    "visibility": visibility[_key == $lang][0].value,
    "defaultVisibility": count(visibility).length == 1 && visibility[0].value,
    "text": text[_key == $lang][0].value[] {
      ${portableTextWithInternalLinks}
    },
    ${assuranceBlock},
    ${backgroundContentBlock},
    ${baniereBlock},
    ${columnBlock},
    ${ctaImageBlock},
    ${ctaTextBlock},
    ${galleryBlock},
    ${imageFullBlock},
    ${impactBlock},
    ${issuesBlock},
    ${morphoBlock},
    ${mosaicBlock},
    ${quoteBlock},
    ${simpleContentBlock},
    ${subscribeBlock},
    ${titleBlock},
    ${tableBlock},
    ${sizeGuideImagesBlock},
    ${videoBlock},
    ${tracabiliteBlock},
    ${galleryOfProductsAndLinksBlock},
    ${teasingVideoBlock},
  }
`
